import WOW from 'wow.js';
import 'bootstrap';
import 'select2';

window.axios = require('axios');
window.$ = window.jQuery = require('jquery');

require('./components/aside');
require('./components/awards');
require('./components/menu');
require('./components/slider');
require('./components/cart');


$(function () {
    new WOW().init();

    // Bullet pagination height
    const setPaginationHeight = () => {
        setTimeout(() => {
            let image_h = $('.attachment-uq_home_slider_thumbnail').outerHeight();
            let swipper_pag = $('#home-slider-pagination');
            swipper_pag.css('top', image_h + 20 + 'px')
        }, 150);
    }

    setPaginationHeight();

    //On resize event
    window.onresize = (e) => {
        setPaginationHeight();
    }

    console.log("%cMade with ♥ by Uniqoders - https://uniqoders.com", "color:#403c5e; font-size:36px")
});