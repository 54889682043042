activeButton = ($this, $container) => {
    $this.toggleClass('active');
    $this.attr('aria-expanded', $this.hasClass('active'))

    if ($this.hasClass('active')) {
        $container.trigger('toggle-open');
    } else {
        $container.trigger('toggle-close');
    }
}

toggleAwards = function () {
    let containers = ['winners', 'award-info'];

    let $btn = $('[data-toggle="winners"]');
    let $container = $('[data-container="winners"],[data-container="award-info"]');

    $btn.on('click', function (e) {
        let $this = $(e.currentTarget);
        let $parent = $this.closest('[data-container="award"]');
        let $container = $(`[data-container="${$this.data('toggle')}"]`, $parent);

        activeButton($this, $container)
    });

    $container.on('toggle-open', function (e) {
        let $this = $(e.currentTarget);
        let $parent = $this.closest('[data-container="award"]');

        let container = containers.find((container) => container !== $this.data('container'));
        $(`[data-container="${container}"]`, $parent).collapse('hide');

        // First status
        $this.collapse('show');
    });

    $container.on('toggle-close', function (e) {
        let $this = $(e.currentTarget);
        let $parent = $this.closest('[data-container="award"]');

        let $btn = $(`[data-toggle="${$this.data('container')}"]`, $parent);

        $btn.removeClass('active');
        $btn.attr('aria-expanded', $this.hasClass('active'));

        let container = containers.find((container) => container !== $this.data('container'));
        $(`[data-container="${container}"]`, $parent).collapse('show');

        $this.collapse('hide');
    });
}

$(function () {
    toggleAwards();
});