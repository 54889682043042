$(function () {
    $('[data-toggle="add-to-cart"]').on('submit', function (e) {
        e.preventDefault();

        let $form = $(this);
        let $submit = $('button:submit', $form);
        let product_url = $('input:radio[name="item-id"]:checked', $form).val();

        if (!product_url) {
            product_url = $('input:hidden[name="item-id"]', $form).val();
        }

        console.log(product_url);

        let text = $submit.text();

        $.ajax({
            type: 'get',
            url: product_url,
            beforeSend: function () {
                $submit.html('<div class="uq-loader"></div>');
                $submit.attr('disabled', true);
            },
            complete: function () {
                $submit.text(text);
                $submit.attr('disabled', false);

                let $success = $('[data-container="cart-confirmation"]', $form);

                $success.collapse('show');

                setTimeout(function () {
                    $success.collapse('hide')
                }, 5000);
            },
            success: function () {
                let $cart = $('[data-container="resume-cart"]');
                let $count = $('.total-items', $cart);

                let total_items = parseInt($count.text());
                $count.text(++total_items);

                if (total_items === 0) {
                    $cart.addClass('empty');
                } else {
                    $cart.removeClass('empty');
                }
            },
        });
    });
});