//Aside year selector
import PerfectScrollbar from "perfect-scrollbar";

$(function () {
    const $select2 = $('.uq-aside-year-selector');

    $select2.select2();

    $select2.on('change', function (e) {
        let $this = $(this);
        console.log(window.location.href)
        console.log($this.val())

        window.location.href = $this.val();
    });

    $select2.on("select2:open", function () {
        new PerfectScrollbar('.select2-results__options');
    });
});