// Navbar animation on hover
$(function () {
    const _navbar = $('.uq-header-navbar');
    const _body = $('body');
    const _html = $('html');
    const _header = $('[data-plugin="sticky"]');
    const _header_navbar = $('#navbarSupportedContent');
    const _button_close = $('.hamburger.navbar-toggler');
    let h_height = $('#top').outerHeight();
    const _btnSearch = $('[data-toggle="search-box"]');
    const _form = $('.uq-search-form');

    const closeNav = () => {
        _navbar.removeClass('show');
        _body.removeClass('menu-open');
        _html.removeClass('menu-open');
        _button_close.removeClass('is-active').attr('aria-expanded', 'false');
        _header_navbar.attr('aria-expanded', false);
    }

    const toggleMenu = function (e) {
        if (_header.hasClass('sticky') === false) {
            if (e.type === 'mouseenter') {
                _navbar.addClass('show');
            } else {
                _navbar.removeClass('show');

            }
        }
    }

//Sticky on mouse scroll
    if (_header.length > 0) {
        let position = _header.offset().top + h_height;

        if (window.pageYOffset >= position) {
            _header.addClass('sticky');
            _html.addClass('sticky');
            _body.css('margin-top', h_height);
        }

        $(window).scroll(function () {
            if ($(document).scrollTop() > position) {
                _header.addClass('sticky');
                _html.addClass('sticky');
                _body.css('margin-top', h_height);
            } else {
                _header.removeClass('sticky');
                _html.removeClass('sticky');
                _body.css('margin-top', 0);
                closeNav();
            }
        });
    }

//Hamburguer
    if (_button_close.length > 0) {
        _button_close.on('click', function () {
            let $this = $(this);

            if (!$this.hasClass('is-active') && $this.attr('aria-expanded') === 'false') {
                $this.addClass('is-active');
                _header_navbar.attr('aria-expanded', true);

                _body.addClass('menu-open');
                _html.addClass('menu-open');
            } else {
                $this.removeClass('is-active');
                _header_navbar.attr('aria-expanded', false);

                _body.removeClass('menu-open');
                _html.removeClass('menu-open');
            }
        });
    }

//Search box
    function searchBox() {
        _btnSearch.on('click', function (e) {
            if (!_form.hasClass('open')) {
                e.preventDefault();

                $('.uq-search--container').addClass('open');
                _form.toggleClass('open');

                if (_form.hasClass('open')) {
                    _form.find('input').focus();
                }
            }
        })
    }

    $(window).on('click', function (e) {
        if ($(e.target).closest(_header).length === 0 && $(e.target).closest(_button_close).length === 0) {
            closeNav();
            if (_form.hasClass('open')) {
                $('.uq-search--container').removeClass('open');
                _form.removeClass('open');
            }
        }
    });

    if (window.screen.width >= 992) {
        $('.navbar-nav-list > .nav-item > .nav-link', _navbar).on('mouseenter', toggleMenu);
        _navbar.on('mouseleave', toggleMenu);
        searchBox();
    }
});
