import Swiper, {Autoplay, EffectFade, Pagination, Navigation} from "swiper";

//Swiper
Swiper.use([Pagination, Autoplay, EffectFade, Navigation]);

$(function () {
    // #region Home Slider
    new Swiper('.home-hero-slider', {
        spaceBetween: 30,
        loop: true,
        autoHeight: false,
        effect: 'fade',
        fadeEffect: {
            crossFade: true
        },
        speed: 1500,
        autoplay: {
            delay: 10000,
            disableOnInteraction: false,
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            dynamicBullets: true
        },
    });
    // endregion

    $('.swiper-container').each(function (i) {
        const mySwiper = new Swiper($('#magazine-slider')[i], {
            speed: 300,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
                dynamicBullets: true
            },
            navigation: {
                nextEl: $('#swiper-magazine-right')[i],
                prevEl: $('#swiper-magazine-left')[i],
            },
            breakpoints: {
                0: {
                    slidesPerView: 1.6,
                    spaceBetween: 40,
                    centeredSlides: true,
                    loop: true
                },
                575: {
                    slidesPerView: 2.2,
                    spaceBetween: 25,
                    centeredSlides: false,
                },
                768: {
                    slidesPerView: 3.6,
                    spaceBetween: 35,
                },
                992: {
                    slidesPerView: 5,
                    spaceBetween: 50,
                },
            }

        });
    });
});